import React from 'react';
import Navbar from './Navbar';
import Home from './Home';
import { Link } from "react-router-dom";

const ManageProjects = () =>{
    

    return(
      
         <div>
          

              <Home/>
           
         </div>
     
    );
};

export default ManageProjects;